// @ts-ignore
import { createApp } from 'vue'
// @ts-ignore
import {message} from "ant-design-vue";
import router from './router'
import App from './App.vue'
import store from './store'
import './registerServiceWorker'

import '@/styles/index.less'
import 'normalize.css';
import 'animate.css/animate.min.css'

message.config({
  duration: 1,
  maxCount: 3,
});

createApp(App).use(store).use(router).mount('#app')
