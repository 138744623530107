import {RouteRecordRaw} from "vue-router";
import {commonRouterList} from "@/router/common";
import {timetableRouterList} from "@/router/timetable";
import {subjectsRouterList} from "@/router/subjects";
import {conferenceRouterList} from "@/router/conference";

export const mergeRouters: Array<RouteRecordRaw> = [
  ...commonRouterList,
  ...timetableRouterList,
  ...subjectsRouterList,
  ...conferenceRouterList,
]
