import {Commit} from "vuex";

export enum ECourseType {
  zhi = '1',
  yin = '2',
  hua = '3',
}


export interface ICourseware {
  imgUrl: string,    // 图片或视频的地址
  time: number | null,   // 当前ppt要播放的时长
  typeName: string | null,  // 当时ppt 是否是知 引 画
  mediaType: string,      //
  id: any,
}

export interface ICoursewareState {
  showMenu: boolean,
  slideMenuShow: boolean,
  coursewareState: Array<ICoursewareState>,
  fullscreen: boolean,
  classTimeCounter: number,   // 一节课的时间, 单位秒
  coursewareTimer: any,       // 计时器
  coursewareIndex: number,    // 当前课程PPT进度
}

export const courseware = {
  namespace: true,
  state: () => ({
    showMenu: true,
    slideMenuShow: true,
    coursewareState: [],
    fullscreen: true,
    classTimeCounter: 0,
    coursewareTimer: null,
    coursewareIndex: 0,
  }) as ICoursewareState,
  getters: {
    getCoursewareShowMenuState: (state) => {
      return state.showMenu;
    },
    getCoursewareSlideMenuState: (state) => {
      return state.slideMenuShow;
    },
    getCoursewareFullscreenState: (state) => {
      return state.fullscreen;
    },
    getCoursewareClassTimeCounterState: (state) => {
      return state.classTimeCounter;
    },
    getCoursewareState: (state) => {
      return state.coursewareState;
    },
    getCoursewareIndex: (state) => {
      return state.coursewareIndex;
    },
  },
  actions: {
    actCoursewareShowMenuState: async (context: { commit: Commit, state: ICoursewareState }, show: boolean) => {
      context.commit('changeCoursewareShowMenuState', show);
    },
    actCoursewareSlideMenuState: async (context: { commit: Commit, state: ICoursewareState }) => {
      context.commit('changeCoursewareSlideMenuState');
    },
    actCoursewareFullscreenState: async (context: { commit: Commit, state: ICoursewareState }) => {
      context.commit('changeCoursewareFullscreenState');
    },
    actStarCountTimeState: async (context: { commit: Commit, state: ICoursewareState }, type: string) => {
      switch (type) {
        case "start":
          context.commit('classTimeCounterAccumulation');
          break;
        case "suspend":
          context.commit('classTimeCounterStop');
          break;
        default:
          context.commit('classTimeCounterInit');
          break;
      }
    },
    actCoursewareIndexState: async (context: { commit: Commit, state: ICoursewareState }, index: number) => {
      context.commit('changeCoursewareIndex', index)
    },
    actSetCourseware: async (context: { commit: Commit, state: ICoursewareState }, courseware: Array<ICourseware>) => {
      context.commit('setCoursewareState', courseware)
    },
    actSetCoursewareIndex: async (context: { commit: Commit, state: ICoursewareState }, index: number) => {
      context.commit('setCoursewareIndex', index)
    }
  },
  mutations: {
    setCoursewareState: (state, courseware: Array<ICourseware>) => {
      state.coursewareState = courseware;
    },
    changeCoursewareShowMenuState: (state, show: boolean) => {
      state.showMenu = show;
    },
    changeCoursewareSlideMenuState: (state) => {
      state.slideMenuShow = !state.slideMenuShow;
    },
    changeCoursewareFullscreenState: (state) => {
      state.fullscreen = !state.fullscreen;
    },
    classTimeCounterAccumulation: (state) => {
      // 开始计时
      state.coursewareTimer = setInterval(() => {
        state.classTimeCounter += 1;
      }, 1000)
    },
    classTimeCounterStop: (state) => {
      // 暂停计时
      clearInterval(state.coursewareTimer)
    },
    classTimeCounterInit: (state) => {
      // 初始化计时
      if (state.coursewareTimer) {
        clearInterval(state.coursewareTimer);
        state.coursewareTimer = null;
      }
      state.classTimeCounter = 0;
    },
    changeCoursewareIndex: (state, index: number) => {
      // 暂停计时
      state.coursewareIndex = index;
    },
    setCoursewareIndex: (state, index: number) => {
      state.coursewareIndex = index;
    }
  }
}
