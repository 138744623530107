
import {computed, defineComponent, watch} from "vue";
import {useStore} from "vuex";

export default defineComponent ({
  name: "RouteComponent",
  data() {
    return {}
  },
  setup(){
    const store = useStore();
    const keepaliveComponentList = computed(() => store.getters.getKeepaliveComponentList)
    return {
      keepaliveComponentList,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
