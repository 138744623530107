
import RouteComponent from "@/components/routerComponent/RouteComponent.vue";
import {defineComponent} from "vue";
import {ConfigProvider} from 'ant-design-vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN';

export default defineComponent({
  components: {
    RouteComponent,
    AConfigProvider: ConfigProvider,
  },
  setup() {
    return {
      zhCN,
    }
  }
})
