import {Commit, createStore} from 'vuex'
import {courseware} from "@/store/module/courseware.module";


export interface IDwState {
  keepaliveComponentList: Array<string>;
}

let dwState: IDwState = {
  keepaliveComponentList: [],
}

export default createStore({
  state: {
    ...dwState,
  },
  mutations: {
    addKeepaliveComponentList: (state, name: string) => {
      if (state.keepaliveComponentList.indexOf(name) === -1) {
        state.keepaliveComponentList.push(name)
      }
    },
    delKeepaliveComponentList: (state, name: string) => {
      state.keepaliveComponentList.splice(state.keepaliveComponentList.indexOf(name), 1);
    },
  },
  getters: {
    getKeepaliveComponentList: (state) => state.keepaliveComponentList,
  },
  actions: {
    actKeepaliveComponentList: async (context: { commit: Commit, state: IDwState }, params: { type: string, name: string }): Promise<void> => {
      switch (params.type) {
        case 'add':
          context.commit('addKeepaliveComponentList', params.name);
          break;
        case 'del':
          context.commit('delKeepaliveComponentList', params.name)
      }
    },
  },
  modules: {
    courseware: courseware,
  },
})
