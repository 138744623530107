import {RouteRecordRaw} from "vue-router";

export const subjectsRouterList: Array<RouteRecordRaw> = [
  {
    path: '/subject',
    name: 'SubjectIndex',
    component: () => import("@/views/subjects/SubjectIndex.vue"),
    meta: {cName: 'XXX',},
    redirect: '/subject/course',
    children: [
      {
        path: '/subject/course',
        name: 'CourseSelect',
        component: () => import('@/views/subjects/CourseSelect/CourseSelect.vue'),
        meta: {cName: '选择课程', },
      },
      {
        path: '/subject/topic',
        name: 'CourseTopic',
        // component: () => import('@/views/subjects/CourseTopic/CourseTopic.vue'),
        component: () => import('@/views/subjects/CourseTopic/CourseTopic2.vue'),
        meta: {cName: '课题选择', },
      },
    ]
  }
]
